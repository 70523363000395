import React from "react";

import Page from "../../components/page/page";

const AboutPage = () => {
  return (
    <Page title="About Remnant Performance">
      <div className="copy">
        <p>
          Remnant Performance was started in 2016 in an effort to provide cost
          effective performance upgrades for the Genesis Coupe platform. As a
          Christian owned business, Remnant Performance was set up with the
          mindset of “honor God through the way we serve our customers”.
        </p>

        <p>
          The prototype of Remnant Performance’s Genesis Coupe BK2 3.8 turbo kit
          was completed in October of 2016. Due to a lack of tuning capability,
          Remnant Performance partnered with AlphaSpeed to help launch the
          AlphaSpeed tune dealers network around the country.
        </p>

        <p>
          Sadly in October of 2017, Remnant Performance’s fabricator passed away
          right before being able to help the company bring the turbo kit to the
          market. After a time of mourning, Remnant Performance started
          searching for a new fabricator. The search was long and difficult but
          through trust in God, Remnant Performance was able to find a
          fabrication partner that was not only able to help provide the
          necessary piping for the kit, they were also able to help expand the
          vision for the turbo kit.
        </p>

        <p>
          As part of AlphaSpeed’s premier tuning partner network, Remnant
          Performance is able to provide tuning to a whole host of vehicles
          while providing pricing and a level of customer service that helps
          them “Honor God in the way we serve our customers”.
        </p>
      </div>
    </Page>
  );
};

export default AboutPage;
