import React from "react";

import ProductDetail from "../../components/product-detail/product-detail";
import PhotoGallery from "../../components/photo-gallery/photo-gallery";

import "./home-page.scss";

const HomePage = () => {
  return (
    <>
      <section className="section-container home-section">
        <span className="large-logo fade-in"></span>
      </section>
      <section className="section-container">
        <ProductDetail />
        {/*
        <div className="vehicle-capture">
          BK2
          <span className="vehicle-capture__spec">
            KIT
          </span>
        </div>
        */}

        {/*<hr className="section-divider" />*/}

        <div className="services">
          <div className="services__card">
            <h4 className="services__heading">
              As an authorized tune dealer, we offer AlphaSpeed tuning services
              to a variety of vehicles in the North Carolina area including:
            </h4>
            <ul className="services__list">
              <li>Hyundai Genesis Coupe 2.0T & 3.8 AT and MT</li>
              <li>Hyundai Sonata/Kia Optima</li>
              <li>Scion/Toyota/Subaru FRS/BRZ</li>
              <li>Mitsubishi EVO</li>
              <li>Nissan 2013+ Vehicles</li>
            </ul>
            <p className="services__more-info">
              For more information including pricing, please contact us through
              Facebook, Instagram, phone or email
            </p>
          </div>
        </div>
      </section>
      <section className="section-container gallery-section">
        <PhotoGallery />
      </section>
    </>
  );
};

export default HomePage;
