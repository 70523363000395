import React from "react";

import ProductDetail from "../../components/product-detail/product-detail";
import Page from "../../components/page/page";

import "./product-detail-page.scss";

const ProductDetailPage = props => {
  const { history, updateOrderDetails } = props;

  return (
    <Page>
      <ProductDetail
        isAddToCart
        history={history}
        updateOrderDetails={updateOrderDetails}
      />
      <span className="details-list-heading">Your choice of turbo</span>
      <ul className="details-list">
        <li>
          70mm journal bearing (standard) - spool: ~ 3,000rpms / expected gain
          at 6psi: 120-130whp & 105-110tq
        </li>
        <li>
          67mm ceramic ball bearing (+$425) - spool: ~ 2,300rpms / expected gain
          at 6psi: 120-130whp & 105-110tq
        </li>
        <li>
          76mm journal bearing (+$50) - spool: ~ 3,800rpms / expected gain at
          6psi: 180+whp & 180+tq (not recommended for stock engine)
        </li>
        <li>
          76mm ceramic ball bearing (+$475) - spool: ~ 3,000rpms / expected gain
          at 6psi: 180+whp & 180+tq (not recommended for stock engine)
        </li>
      </ul>

      <span className="details-list-note">
        * spool characteristics assume gutted precats. Add ~500rpms for stock
        precats.
      </span>

      <span className="details-list-heading">What's Included</span>
      <ul className="details-list">
        <li>
          Remnant Performance T4 Turbo Mounting Bracket will all associated
          bolts, nuts and gaskets.
        </li>
        <li>Remnant Performance 3&quot; Turbo Inlet Pipe</li>
        <li>Remnant Performance 2.25&quot; Exhaust U-Pipe merge piping</li>
        <li>Remnant Performance 2.5&quot; Turbo Up-Pipe</li>
        <li>
          Remnant Performance 3&quot; to Dual 2.5&quot; Downpipe (eliminates
          secondary CATs / No test pipes needed)
        </li>
        <li>
          Remnant Performance Front Mount Intercooler with a 24”x8”x3.5” core
          (672 cubic inches)
        </li>
        <li>
          Remnant Performance 2.5” intercooler piping kit with 5-Ply Couplers
          and T-Bolt Clamps
        </li>
        <li>ON3Performance 50mm BOV</li>
        <li>
          ON3Performance 44mm Wastegate with 6psi Spring in open dump
          configuration.
        </li>
        <li>
          Remnant Performance 3.8 Oil Filter Housing with replacement bolts
        </li>
        <li>
          Remnant Performance Oil Cooler Kit with mounting brackets and AN10
          nylon braided lines and AN fittings
        </li>
        <li>Remnant Performance Aluminum Windshield Washer Fluid Reservoir</li>
        <li>
          NGK Iridium 1-step colder spark plugs (NGK 93819 - ILKR8Q7 = gapped to
          .028)
        </li>
        <li>530cc Injectors (BK1 ONLY)</li>
        <li>T4 Turbo Blanket (Black)</li>
        <li>
          Nylon Braided Oil Return Line and Drain Kit with AN fittings and bulk
          head compression fitting for upper oil pan.
        </li>
        <li>Oil Feed Line (-4AN) and associated fittings</li>
        <li>Large S&amp;B Air Intake Filter</li>
        <li>Genuine Hyundai 2.0T Oil Filter with new crush ring.</li>
        <li>Exhaust Heatwrap kit</li>
        <li>Aluminized Thermal Wrap</li>
        <li>Thermal sleeve (rated for over 1200*F - 3/4”)</li>
        <li>Vacuum Line and vacuum tee connections</li>
        <li>Power Steering Reservoir &amp; Fuse Box Relocation Bracket</li>
        <li>Oil Catch Can, custom mounting bracket and nylon braided lines</li>
        <li>Car horn Relocation bracket</li>
        <li>Nylon Braided lines for Power Steering Reservoir Relocation</li>
        <li>T-Bolt and V-Band Clamps</li>
        <li>Stainless Steel MLS exhaust gaskets</li>
        <li>Stepper Drill Bit (quick connect)</li>
        <li>
          Plastic razors, scrapers &amp; high Temp silicone for Oil Pan removal
          and reattachment
        </li>
        <li>All needed screws, bolts, nuts and mounting hardware</li>
        <li>Access to in depth Installation Instructions and Videos</li>
      </ul>
    </Page>
  );
};

export default ProductDetailPage;
