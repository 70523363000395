import React, { useContext, useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import Header from "./components/header/header";
import Footer from "./components/footer/footer";

import HomePage from "./pages/home-page/home-page";
import ProductDetailPage from "./pages/product-detail-page/product-detail-page";
import AboutPage from "./pages/about-page/about-page";
import TermsPage from "./pages/terms-page/terms-page";
import ThankYouPage from "./pages/thank-you-page/thank-you-page";

const AppContext = React.createContext();

function App() {
  const [orderDetails, setOrderDetails] = useState(null);

  return (
    <AppContext.Provider value={{ order: orderDetails }}>
      <Router>
        <Header />
        <main>
          <Route
            render={props => {
              window.scrollTo(0, 0);
              return null;
            }}
          />
          <Switch>
            <Route exact path="/" render={() => <HomePage />} />
            <Route
              exact
              path="/products/turbo-kit"
              render={props => (
                <ProductDetailPage
                  {...props}
                  updateOrderDetails={setOrderDetails}
                />
              )}
            />
            <Route exact path="/about" render={() => <AboutPage />} />
            <Route
              exact
              path="/terms-and-conditions"
              render={() => <TermsPage />}
            />
            <ProtectedRoute exact path="/thank-you" component={ThankYouPage} />
          </Switch>
        </main>
        <Footer />
      </Router>
    </AppContext.Provider>
  );
}

const ProtectedRoute = props => {
  const { component: Component } = props;
  const context = useContext(AppContext);

  return (
    <Route
      {...props}
      component={null}
      render={() =>
        context.order ? (
          <Component order={context.order} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default App;
