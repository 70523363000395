import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./header.scss";
import icon from "../../remnant_icon.png";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <header className="header">
      <button
        className="mobile-menu-button"
        type="button"
        onClick={() => setShowMenu(!showMenu)}
      >
        <div className="menu-icon">
          <span></span>
          <span></span>
          <span></span>
        </div>
        Menu
      </button>
      <nav className="main-nav" style={!showMenu ? { display: "none" } : null}>
        <button
          className="close-menu-button"
          type="button"
          onClick={() => setShowMenu(!showMenu)}
        >
          &times;
        </button>
        <Link className="nav-icon" to="/" onClick={() => setShowMenu(false)}>
          <img className="icon" src={icon} />
        </Link>
        <Link
          className="nav-link"
          to="/products/turbo-kit"
          onClick={() => setShowMenu(false)}
        >
          Products
        </Link>
        {/*
        <Link className="nav-link" to="/">
          Services
        </Link>
        */}
        <Link
          className="nav-link"
          to="/about"
          onClick={() => setShowMenu(false)}
        >
          About
        </Link>
        {/*
        <Link className="nav-link" to="/">
          Contact
        </Link>
        */}
      </nav>
      <span className="phone-number">
        <i className="icon-phone phone-number__icon" />
        919.346.7635
      </span>
    </header>
  );
};

export default Header;
