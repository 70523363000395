import React from "react";

import { Link } from "react-router-dom";

import "./button.scss";

const Button = ({
  className = "",
  primary,
  href,
  children,
  onClick = null
}) => {
  if (href) {
    return (
      <Link
        className={`btn ${primary ? "btn--primary" : ""} ${className}`}
        to={href}
        onClick={onClick}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <button
        className={`btn ${primary ? "btn--primary" : ""} ${className}`}
        type="button"
        onClick={onClick}
      >
        {children}
      </button>
    );
  }
};

export default Button;
