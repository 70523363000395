import React, { PureComponent } from "react";

import { Link } from "react-router-dom";

import ProductGallery from "../product-gallery/product-gallery";
import NewBadge from "../new-badge/new-badge";
import Price from "../price/price";
import Button from "../button/button";
import PaymentForm from "../payment-form/payment-form";

import "./product-detail.scss";
import { throwStatement } from "@babel/types";

const originalPrice = 3695;
const price = 3295;
let attempts = 0;

//BK1 $+300
//Sales Tax is 7.25% - before shipping cost;

class ProductDetail extends PureComponent {
  static defaultProps = {
    price: 3295,
    originalPrice: 3695
  };

  state = {
    acceptedTerms: false,
    originalPrice: this.props.originalPrice,
    price: this.props.price,
    selectedOption: "70JB",
    selectedVehicle: "BK2"
  };

  onOptionChange = e => {
    const { value } = e.target;

    this.setState(
      {
        selectedOption: value
      },
      () => {
        this.calculatePrice();
      }
    );
  };

  onVehicleChange = e => {
    const { value } = e.target;

    this.setState(
      {
        selectedVehicle: value
      },
      () => {
        this.calculatePrice();
      }
    );
  };

  toggleCheckbox = e => {
    this.setState({
      acceptedTerms: e.target.checked
    });
  };

  calculatePrice = () => {
    const { selectedOption, selectedVehicle } = this.state;
    const { price, originalPrice } = this.props;

    let addedCost = 0;

    if (selectedOption === "67BB") {
      addedCost += 425;
    } else if (selectedOption === "76JB") {
      addedCost += 50;
    } else if (selectedOption === "76BB") {
      addedCost += 475;
    }

    if (selectedVehicle === "BK1") {
      addedCost += 300;
    }

    this.setState({
      price: price + addedCost,
      originalPrice: originalPrice + addedCost
    });
  };

  render() {
    const { isAddToCart } = this.props;
    const { acceptedTerms, price, originalPrice } = this.state;

    return (
      <div className="product-detail">
        <div className="product-detail__display">
          <NewBadge />
          <ProductGallery
            images={[
              { src: "/images/turbo-kit_medium.jpg", alt: "Turbo Kit" },
              {
                src: "/images/turbo-kit-exhaust_medium.jpg",
                alt: "Turbo Kit Exhaust Piping"
              },
              { src: "/images/t70-turbo_medium.jpg", alt: "T70 Turbo" }
            ]}
          />
        </div>

        <div className="product-detail__details">
          <h2 className="product-detail__name">BK2/BK1 3.8L Turbo Kit</h2>
          <Price value={originalPrice} markdownValue={price} />
          <span className="product-detail__price-note">
            * Plus taxes and regional shipping premium
          </span>

          <p className="product-detail__description">
            A potent turbo kit specially made for 3.8L Genesis Coupes. This kit
            fits manual transmission and automatic transmission 3.8L's.{" "}
            {!this.props.isAddToCart ? "This kit includes:" : ""}
          </p>

          {!isAddToCart && (
            <>
              <ul className="product-detail__list">
                <li>
                  ON3Performance 70mm Turbo (options available, see below)
                </li>
                <li>Remnant Performance 3" Turbo Inlet Pipe</li>
                <li>Remnant Performance 2.25" Exhaust merge piping (U-pipe)</li>
                <li>Remnant Performance 2.5" Turbo Up-Pipe</li>
                {/*<li>
                  Remnant Performance 3" to Dual 2.5" Downpipe (eliminates
                  secondary CATs)
                  </li>*/}
                <li style={{ listStyle: "none" }}>and more...</li>
              </ul>

              <Button
                href="/products/turbo-kit"
                className="product-detail__button"
                primary
              >
                View Details
              </Button>
            </>
          )}

          {isAddToCart && (
            <>
              <div className="product-detail__option">
                <label htmlFor="vehicle-options">Vehicle:</label>
                <select id="vehicle-options" onChange={this.onVehicleChange}>
                  <option value="BK2">2013-2016 Genesis Coupe / BK2</option>
                  <option value="BK1">
                    2010-2012 Genesis Coupe / BK1 (+$300){" "}
                  </option>
                </select>
              </div>
              <div className="product-detail__option">
                <label htmlFor="turbo-options">Turbo:</label>
                <select id="turbo-options" onChange={this.onOptionChange}>
                  <option value="70JB">70mm journal bearing (standard)</option>
                  <option value="67BB">
                    67mm ceramic ball bearing (+$425)
                  </option>
                  <option value="76JB">76mm journal bearing (+$50)</option>
                  <option value="76BB">
                    76mm ceramic ball bearing (+$475)
                  </option>
                </select>
              </div>

              <div className="agree-to-terms">
                <input
                  type="checkbox"
                  checked={acceptedTerms}
                  onChange={this.toggleCheckbox}
                />
                <span>
                  Please check the box to indicated that you agree with the{" "}
                  <Link to="/terms-and-conditions">Terms and Conditions</Link>{" "}
                  before purchasing
                </span>
              </div>

              <PaymentForm amount={price} />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default ProductDetail;
