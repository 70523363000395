import React from "react";

import Page from "../../components/page/page";

const TermsPage = () => {
  return (
    <Page title="Terms & Conditions">
      <div className="copy">
        <h2>Remnant Performance LLC User Agreement</h2>
        <p>
          The following User Agreement (“Agreement”) governs your (“you” or
          “your”) use of www.remnantperformance.com (“Website”) and any other
          services as provided by Remnant Performance LLC, a North Carolina
          limited liability company (“REMNANT PERFORMANCE,” “we,” “us” or
          “our”). By using this website, you are agreeing to accept the terms of
          this agreement and to abide by them. The Agreement may be modified
          from time to time; the date of the most recent revisions will appear
          on the Website. Continued access of the Website by you will constitute
          your acceptance of any changes or revisions to the Agreement.
        </p>

        <h3>REMNANT PERFORMANCE Services</h3>
        <p>
          REMNANT PERFORMANCE provides an online service (the “Service”) in
          which you can purchase after-market automotive parts and other
          automotive related products, obtain information related to automobiles
          and the products REMNANT PERFORMANCE sells and correspond with REMNANT
          PERFORMANCE representatives. REMNANT PERFORMANCE manufactures the
          automotive parts (“Products”) either directly or through an authorized
          contract manufacturer. REMNANT PERFORMANCE also provides vehicular
          tuning as an authorized and official ALPHASPEED tune dealer. REMNANT
          PERFORMANCE also provides links to other products and services
          provided by our partners in the ALPHASPEED tune dealer network. All
          Products are for “OFF ROAD USE ONLY” unless otherwise stated.
        </p>

        <h3>Limitation of Liability</h3>
        <p>
          YOU ACKNOWLEDGE THAT YOU ARE USING THE WEBSITE AND ANY PRODUCTS OR
          SERVICES OFFERED OR SOLD THROUGH THE WEBSITE OR DIRECTLY IN PERSON,
          INCLUDING THE INSTALLATION OF ANY SUCH PRODUCTS, OR TUNING OF YOUR
          VEHICLE, IS AT YOUR OWN RISK. REMNANT PERFORMANCE WILL NOT BE LIABLE
          TO YOU OR ANY THIRD PARTIES FOR ANY DIRECT, INDIRECT, SPECIAL,
          PUNITIVE, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR ANY
          DAMAGES WHATSOEVER, EVEN IF REMNANT PERFORMANCE HAS PREVIOUSLY ADVISED
          OF THE POSSIBILITY OF SUCH DAMAGES, WHETHER IN AN ACTION UNDER
          CONTRACT, NEGLIGENCE, OR ANY OTHER THEORY, ARISING OUT OF OR IN
          CONNNECTION WITH THE USE, MISUSE, INABILITY TO USE, OR PERFORMANCE OF
          INFORMATION, SERVICES, PRODUCTS AND MATERIALS AVAILABLE FROM THIS
          SITE. THE LIMITATIONS WILL APPLY NOTWITHSTANDING TO ANY FAILURE OF
          ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.
        </p>

        <p>
          YOU ACKNOWLEDGE AND AGREE THAT SOME PRODUCTS MAY NOT BE LAWFUL FOR USE
          ON PUBLIC ROADWAYS. NO WARRANTY IS MADE OR IMPLIED REGARDING THE
          LEGALITY OF ANY PRODUCTS OFFERED THROUGH THE WEBSITE OR BY REMNANT
          PERFORMANCE. IT IS YOUR RESPONSIBILITY TO DETERMINE WHETHER YOUR
          AUTOMOBILE OR ANY ALTERATIONS MADE IN CONNECTION WITH PRODUCTS OFFERED
          THROUGH THE WEBSITE ARE LEGAL IN ANY STATE(S) IN WHICH THE AUTOMOBILE
          WILL BE OPERATED.
        </p>

        <p>
          YOU FURTHER ACKNOWLEDGE AND AGREE THAT THE INSTALLATION OF PRODUCTS
          OFFERED THROUGH THE WEBSITE OR CERTAIN MODIFICATIONS MADE TO YOUR
          AUTOMOBILE MAY VOID WARRANTY COVERAGE ON SAID AUTOMOBILE AND THAT
          REMNANT PERFORMANCE BEARS NO LIABILITY FOR ANY SUCH MATTER.
        </p>

        <p>
          Motor vehicles are inherently dangerous objects. The modification of a
          vehicle to attain certain levels of performance outside of what was
          intended by the original manufacturer of the vehicle can make a
          vehicle more dangerous. You fully and unconditionally accept all risks
          associated with the PURCHASE AND/OR INSTALLATION OF ANY PRODUCTS OR
          SERVICES PURCHASED THROUGH REMNANT PERFORMANCE, EITHER THROUGH THE
          WEBSITE OR IN PERSON, AND THE operation and testing of YOUR AUTOMOBILE
          and agree to defend, indemnify and hold REMNANT PERFORMANCE harmless
          from the same. Except for the gross negligence or willful misconduct
          of REMNANT PERFORMANCE, you agree to defend, indemnify and hold
          REMNANT PERFORMANCE harmless from any claims related to the PURCHASE
          AND/OR INSTALLATION OF ANY PRODUCTS OR SERVICES PURCHASED THROUGH
          REMNANT PERFORMANCE AND THE operation and testing of YOUR AUTOMOBILE,
          including but not limited to claims of ownership, bodily injury,
          breach of manufacturer warranty, property damage or death.
        </p>

        <h3>
          Products and Services sold on or through the Website or by REMNANT
          PERFORMANCE directly
        </h3>

        <p>
          REMNANT PERFORMANCE provides products on a build to order basis.
          Products are not normally carried in stock and therefore, you are
          agreeing that all sales are final and non-refundable unless in the
          case that REMNANT PERFORMANCE can no longer build the product for you.
        </p>

        <p>
          The prices displayed on the Website are quoted in United States
          Dollars and are subject to sales tax. All prices displayed on the
          Website include shipping and handling for the contiguous 48 states of
          the USA. All regions outside of this area are subject to an additional
          shipping fee that will be assessed during the sale.
        </p>

        <h3>Shipping and Handling</h3>

        <p>
          REMNANT PERFORMANCE is not directly responsible for any Products lost
          or damaged during the shipping process. That said, the shipping of all
          products is done with insurance for the purchase amount. As stated
          above, shipping and handling is included in the pricing for shipping
          to the contiguous 48 United States. Shipment to other regions will be
          subject to an additional charge to be assessed at time of purchase.
        </p>

        <h3>Return Policy</h3>

        <p>
          As REMNANT PERFORMANCE sells products that are Built to Order, ALL
          SALES ARE FINAL.
        </p>

        <h3>Indemnification</h3>

        <p>
          You agree, whether directly or in the case of a class action, to
          indemnify REMNANT PERFORMANCE and its affiliates, employees, agents
          and/or representatives and to defend and hold each of them harmless
          from any and all claims and liabilities (including attorney’s fees)
          which may arise from your use of Products or Services purchased from
          the Website or directly, from your unauthorized use of material
          obtained through the Website or from your breach of this Agreement or
          from any such acts through your use of the Website or Products or
          Services offered through the Website or REMNANT PERFORMANCE directly.
        </p>

        <h3>Payment, Pricing, Promotions & Purchase Terms</h3>

        <p>
          REMNANT PERFORMANCE accepts payment through PayPal and any services
          that are offered through them (ex: PayPal Credit).
          <br />
          As REMNANT PERFORMANCE sells Products on a Build to Order basis, ALL
          SALES ARE FINAL. Pricing is subject to change based on available
          promotions.
        </p>

        <h3>Arbitration</h3>

        <p>
          All claims and disputes arising under or relating to this Agreement
          are to be primarily settled by binding arbitration in the state of
          North Carolina or another location mutually agreeable to the parties.
        </p>

        <h3>Jurisdiction</h3>

        <p>
          Those who choose to access the Website do so on their own initiative
          and are responsible for compliance with local laws, if and to the
          extent local laws are applicable. REMNANT PERFORMANCE WILL NOT be
          responsible for your compliance with your local laws. You agree that
          this Agreement, for all purposes, shall be governed and construed in
          accordance with the laws of the State of North Carolina applicable to
          contracts to be wholly performed therein, and any action based on,
          relating to, or alleging a breach of this Agreement must be brought in
          a state or federal court in Raleigh, North Carolina if an agreement
          could not be reached through arbitration. In addition, both parties
          agree to submit to the exclusive personal jurisdiction and venue of
          such courts.
        </p>

        <h3>ENTIRE AGREEMENT</h3>

        <p>
          THESE TERMS OF USE, TOGETHER WITH ANY OTHER AGREEMENTS OR TERMS OF USE
          APPEARING OR REFERENCED ON THIS WEBSITE AND/OR ENTERED INTO BETWEEN
          THE PARTIES IN WRITING REPRESENT THE ENTIRE SET OF TERMS AND
          CONDITIONS GOVERNING YOUR USE OF THE PRODUCTS (WHERE APPLICABLE)
          ACCESSED THROUGH THIS WEBSITE, AND IT SUPERSEDES ANY PRIOR PROPOSAL,
          REPRESENTATION, OR UNDERSTANDING BETWEEN THE PARTIES. These terms of
          use may only be modified in writing by REMNANT PERFORMANCE.
        </p>
      </div>
    </Page>
  );
};

export default TermsPage;
