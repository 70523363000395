import React from "react";

import "./page.scss";

const Page = props => {
  const { children, title } = props;
  return (
    <div className="page">
      <div className="page__container">
        <h1 className="page__heading">{title}</h1>
        <div className="page__content">{children}</div>
      </div>
    </div>
  );
};

export default Page;
