import React from "react";

import "./photo-gallery.scss";

const PhotoGallery = () => {
  return (
    <div className="photo-gallery">
      {galleryImages.map((image, idx) => (
        <div
          key={`gi-${idx}`}
          className={`photo-gallery__tile ${
            idx === 2 ? "photo-gallery__tile--4tile" : ""
          }`}
        >
          <div
            className={`photo-gallery__thumbnail`}
            style={{ backgroundImage: `url(${image.src})` }}
          />
        </div>
      ))}
    </div>
  );
};

const galleryImages = [
  { src: "/images/gallery-preview_2.jpg", alt: "placeholder" },
  {
    src: "/images/gallery-preview_7.jpg",
    alt: "Turbo gages"
  },
  {
    src: "/images/gallery-preview_6.jpg",
    alt: "placeholder"
  },
  {
    src: "/images/gallery-preview_1.jpg",
    alt: "Hood clearance"
  },
  {
    src: "/images/gallery-preview_3.jpg",
    alt: "placeholder"
  },
  {
    src: "/images/gallery-preview_4.jpg",
    alt: "placeholder"
  },
  {
    src: "/images/gallery-preview_5.jpg",
    alt: "Blow off valve"
  }
];

export default PhotoGallery;
