import React, { useEffect } from "react";

import "./payment-form.scss";

const PaymentForm = props => {
  const { amount } = props;
  let paymentForm = null;

  function setupForm() {
    if (window.SqPaymentForm) {
      paymentForm = new window.SqPaymentForm({
        // Initialize the payment form elements

        //TODO: Replace with your sandbox application ID
        applicationId: "sandbox-sq0idb-cpP1yC0YTlF-TPNYV8c68g",
        inputClass: "sq-input",
        autoBuild: false,
        // Customize the CSS for SqPaymentForm iframe elements
        inputStyles: [
          {
            fontSize: "16px",
            lineHeight: "24px",
            padding: "16px",
            placeholderColor: "#a0a0a0",
            backgroundColor: "transparent"
          }
        ],
        // Initialize the credit card placeholders
        cardNumber: {
          elementId: "sq-card-number",
          placeholder: "Card Number"
        },
        cvv: {
          elementId: "sq-cvv",
          placeholder: "CVV"
        },
        expirationDate: {
          elementId: "sq-expiration-date",
          placeholder: "MM/YY"
        },
        postalCode: {
          elementId: "sq-postal-code",
          placeholder: "Postal"
        },
        // SqPaymentForm callback functions
        callbacks: {
          /*
           * callback function: cardNonceResponseReceived
           * Triggered when: SqPaymentForm completes a card nonce request
           */
          cardNonceResponseReceived: function(errors, nonce, cardData) {
            if (errors) {
              // Log errors from nonce generation to the browser developer console.
              console.error("Encountered errors:");
              errors.forEach(function(error) {
                console.error("  " + error.message);
              });
              alert(
                "Encountered errors, check browser developer console for more details"
              );
              return;
            }
            //TODO: Replace alert with code in step 2.1
            // alert(`The generated nonce is:\n${nonce}`);
            fetch("/process-payment.php", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                nonce: nonce
              })
            })
              .catch(err => {
                alert("Network error: " + err);
              })
              .then(response => {
                if (!response.ok) {
                  return response
                    .text()
                    .then(errorInfo => Promise.reject(errorInfo));
                }
                return response.text();
              })
              .then(data => {
                console.log(JSON.stringify(data));
                alert(
                  "Payment complete successfully!\nCheck browser developer console for more details"
                );
              })
              .catch(err => {
                console.error(err);
                alert(
                  "Payment failed to complete!\nCheck browser developer console for more details"
                );
              });
          }
        }
      });

      paymentForm.build();
    }
  }

  function onGetCardNonce(e) {
    e.preventDefault();

    // Request a nonce from the SqPaymentForm object
    if (paymentForm) {
      paymentForm.requestCardNonce();
    }
  }

  useEffect(() => {
    setupForm();
  }, []);

  return (
    <div id="form-container">
      <div id="sq-card-number"></div>
      <div id="sq-expiration-date"></div>
      <div id="sq-cvv"></div>
      <div id="sq-postal-code"></div>
      <button id="sq-creditcard" onClick={onGetCardNonce}>
        Pay ${amount}
      </button>
    </div>
  );
};

export default PaymentForm;

/*
createOrder = (data, actions) => {
    const { price, selectedVehicle, selectedOption } = this.state;

    return actions.order.create({
      purchase_units: [
        {
          description: `${selectedVehicle} 3.8L Turbo Kit - ${selectedOption}`,
          amount: {
            value: (price * 1.0725).toFixed(2),
            breakdown: {
              item_total: {
                currency_code: "USD",
                value: price
              },
              tax_total: {
                currency_code: "USD",
                value: (price * 0.0725).toFixed(2)
              }
            }
          }
        }
      ]
    });
  };

  onShippingChange = (data, actions) => {
    attempts++;

    const { price, selectedOption } = this.state;
    let shippingCost = 0;

    if (data.shipping_address.country_code === "US") {
      if (
        data.shipping_address.state === "AK" ||
        data.shipping_address.state === "HI"
      ) {
        shippingCost = 100;
      }
    } else if (data.shipping_address.country_code === "CA") {
      shippingCost = 150;
    } else {
      shippingCost = 200;
    }

    return actions.order.patch([
      {
        op: "replace",
        path: "/purchase_units/@reference_id=='default'/amount",
        value: {
          currency_code: "USD",
          value: (price * 1.0725 + shippingCost).toFixed(2),
          breakdown: {
            item_total: {
              currency_code: "USD",
              value: price
            },
            tax_total: {
              currency_code: "USD",
              value: (price * 0.0725).toFixed(2)
            },
            shipping: {
              currency_code: "USD",
              value: shippingCost
            }
          }
        }
      }
    ]);
  };

  onApprove = (data, actions) => {
    const { history, updateOrderDetails } = this.props;

    return actions.order.capture().then(function(details) {
      console.log("order details:", details);

      if (updateOrderDetails) {
        updateOrderDetails(details);
      }

      if (history) {
        history.push("/thank-you");
      }
    });
  };

  */
