import React from "react";

import { Link } from "react-router-dom";

import "./footer.scss";

const Footer = () => (
  <footer className="footer">
    <div className="footer__container">
      &copy; {new Date().getFullYear()} Remnant Performance
      <nav className="footer__links">
        <Link className="footer__link" to="/terms-and-conditions">
          Terms & Conditions
        </Link>
        <a
          href="https://www.facebook.com/remnantperformance"
          className="footer__link"
        >
          <i className="icon-facebook" />
        </a>
        <a
          href="https://instagram.com/remnantperformance"
          className="footer__link"
        >
          <i className="icon-instagram" />
        </a>
      </nav>
    </div>
  </footer>
);

export default Footer;
